import * as React from "react"
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"


import Layout from '../components/layout.js'
import HeroShort from '../components/hero-short.js'
import Contact from '../components/contact.js'

const AboutPage = () => {
  return (
    <Layout>

    <Helmet>
      <title>About Me | Billy Dawson</title>
      <meta property="description" content="I’m a big fan of travelling, running and great design. You’ll normally find me looking into the next destination or skandi homeware." />
    </Helmet>

      <HeroShort title="About 👋🏻" />

      <div className="section main">
        <div className="wrap container">
          <div className="row content-image">
            <div className="col-xs-12 col-md-8">
              <p>Originally from Manchester, I moved down to Brighton in 2017. Mainly for the beach and great people.</p>
              <p>I’m a big fan of travelling, running and good design. You’ll normally find me looking into the next destination or mid-century furniture that I definitely don't have the space for...</p>
              <p>I work at a great agency in central Brighton <a href="https://helloseed.co.uk">(Seed)</a> with a client base that spreads from one man bands to e-commerce giants. Most of my time at Seed is spent on creating bespoke software, websites or internal efficiency tools. Freelancing allows me the space to work with people that don’t have the budget for a big agency, like startups or local businesses.</p>
            </div>

            <div className="col-xs-12 col-md-4">
              <StaticImage src="../images/me.png" alt="eBook Image" className="circle-image" width={700} aspectRatio={1} quality={80} />
            </div>
          </div>
        </div>
      </div>

      <div className="section main">
        <div className="wrap container">

          <div className="row list-items">
            <div className="col-xs-12 col-md-4">
              <h3>What I do well</h3>
              <ul>
                <li><p>Building performant websites and apps with a range of technologies.<br/><span className="muted">(HTML, CSS, SCSS, JS, ExpressJS, Gatsby, MongoDB, Ruby on Rails etc...)</span></p></li>
                <li><p>Obsessing (healthily) over the details to make sure products work as best they can.</p></li>
                <li><p>Taking in the wider context of  digital projects.<br/><span className="muted">(Environmental + Social Impact)</span></p></li>
              </ul>
            </div>

            <div className="col-xs-12 col-md-4">
              <h3>What I’m learning</h3>
              <ul>
                <li><p>More about complex, scalable cloud system architecture.</p></li>
                <li><p>The maths behind computer science, and why things work as they do.</p></li>
                <li><p>How to communicate the environmental costs of ‘everyday’ digital products.</p></li>
              </ul>
            </div>

            <div className="col-xs-12 col-md-4">
              <h3>Non-work-y things</h3>
              <ul>
                <li><p>Watching Basketball.</p></li>
                <li><p>Swimming + paddle boarding around Brighton.</p></li>
                <li><p>Reading classic Sci-Fi novels.</p></li>
                <li><p>Running <a href="https://fiddleandthorn.com">Fiddle & Thorn 🌿</a></p></li>
              </ul>
            </div>

          </div>
        </div>
      </div>

      <Contact colour="green" />

    </Layout>
  )
}

export default AboutPage
